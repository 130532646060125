import styled from "@emotion/styled";
import { Box } from "@mui/material";


export const FooterBox = styled(Box)`
  background: #271427;
  width: 100%;
  border-top-width: 8px;
  border-color: #713973;
  border-top-width: 8px;
`;

export const FooterContainer = styled(Box)`
  margin: auto;
  max-width: 1360px;
  padding-right: 20px;
  padding-left: 20px;
`;

export const FooterText = styled(Box)`
  color: #9d979d;
  font-size: 16px;
  font-weight: 400;
`;
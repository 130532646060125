import React from 'react'
import Layout from '../Layout/Layout'
import Hero from '../Components/Hero/Hero'
import Features from '../Components/Features/Features'
import Services from '../Components/Services/Services'
import ContactUs from '../Components/ContactUs/ContactUs'
import AboutUs from '../Components/AboutUs/AboutUs'

const Home = () => {
  return (
    <Layout>
      <Hero/>
      <Features/>
      <AboutUs/>
      <Services/>
      <ContactUs/>
    </Layout>
  )
}

export default Home
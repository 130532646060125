import React, { useEffect, useState } from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { ScrollTopButton } from '../Components/Navbar/NavbarStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleUp } from '@fortawesome/free-regular-svg-icons'

const Layout = ({children}) => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false)

  const handleScroll = () => {
    if (window.scrollY > 200) {
      setShowScrollTopButton(true)
    } else {
      setShowScrollTopButton(false)
    }
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div>
        <Navbar/>
        {children}
        <Footer/>
        {showScrollTopButton && (
        <ScrollTopButton className={showScrollTopButton ? 'show' : ''} onClick={scrollToTop}>
          <FontAwesomeIcon color='#1670ca' size='2xl' icon={faCircleUp} />
        </ScrollTopButton>
      )}
    </div>
  )
}

export default Layout
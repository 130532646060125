import styled from "@emotion/styled";
import { Box } from "@mui/material";



export const ServicesContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 equal columns */
  grid-auto-rows: minmax(100px, auto);
  gap: 44px; /* Adjusts spacing between grid items */
  justify-content: center;
  align-items: start;
  padding: 0 50px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 3 equal columns */
    
  }
`;

export const ServiceWrapper = styled(Box)`
  display: flex;
  gap: 20px; 
  margin: 16px 0;
  align-items: center;
`;

export const ServicesHeader = styled(Box)`
    font-size: 42px;
    font-weight: 700;
    color: #161616;
    line-height: 1.125;
    text-align: center;
`;

export const ServicesSubHeader = styled(Box)`
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    text-align: center;
    line-height: 1.5;

`;

export const ServicesHeading = styled(Box)`
    font-size: 1.375rem;
    font-weight: 400;
    color: #161616;
    line-height: 1.25;
    margin-bottom: 5px;
`;

export const ServicesSubHeading = styled(Box)`
    font-size: 16px;
    font-weight: 300;
    color: #808285;
`;

import React from 'react'
import { ErrorContainer } from '../Components/ContactUs/ContactUsStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { Typography } from '@mui/material'

const ErrorBox = ({errorMessage}) => {
  return (
    <ErrorContainer>
        <FontAwesomeIcon size="2x"icon={faCircleExclamation} />
        <Typography>
            {errorMessage}
        </Typography>
    </ErrorContainer>
  )
}

export default ErrorBox
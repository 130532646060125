import styled from "@emotion/styled";
import { Box, Button, Checkbox, TextField } from "@mui/material";


export const ContactUsContainer = styled(Box)`
  display: flex;
  gap: 90px; 
  margin: auto;
  max-width: 1360px;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 1300px) {
    gap: 10px;
  }
`;

export const ContactUsHeader = styled(Box)`
    font-size: 42px;
    font-weight: 700;
    color: #ffff;
    text-align: left;
    line-height: 1.25;
    border-bottom: 3px solid #1670ca;
    width: 219px;
`;

export const ContactUsIntro = styled(Box)`
    font-size: 22px;
    font-weight: 500;
    color: #ffff;
    text-align: left;
    line-height: 1.5;
    margin-top: 32px;
    margin-bottom: 16px;
`;

export const ContactUsDescription = styled(Box)`
    font-size: 16px;
    font-weight: 400;
    color: #ffff;
    text-align: left;
    line-height: 1.5;
    margin-bottom: 28px;
`;

export const ContactUsLabel = styled(Box)`
    font-size: 18px;
    font-weight: 400;
    color: #ffff;
    text-align: left;
    line-height: 3;
`;

export const ContactUsInput = styled(TextField)`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 5;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  
  /* Remove border and box-shadow overall */
  .MuiOutlinedInput-root {
      background: #fff;
      padding: 8px;
      height: 39px;
      border-radius: 0px;

    fieldset {
      border: none; 
    }
    
    &:hover fieldset {
      border: none; 
    }

    &.Mui-focused fieldset {
      border: none;
    }
  }
  .MuiOutlinedInput-root.Mui-focused {
    box-shadow: none;
  }
`;

export const ContactUsPhoneInput = styled(TextField)`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 5;
  width: 50%;
  margin-bottom: 15px;
  border: none;
  
  /* Remove border and box-shadow overall */
  .MuiOutlinedInput-root {
      background: #fff;
      padding: 8px;
      height: 39px;
      border-radius: 0px;

    fieldset {
      border: none; 
    }
    
    &:hover fieldset {
      border: none; 
    }

    &.Mui-focused fieldset {
      border: none; 
    }
  }
  .MuiOutlinedInput-root.Mui-focused {
    box-shadow: none;
  }
`;

export const ContactUsInputLarge = styled(TextField)`
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  line-height: 5;
  width: 100%;
  margin-bottom: 15px;
  border: none;
  
  .MuiInputBase-input {
    height: 172px !important;
  }

  .MuiOutlinedInput-root {
      background: #fff;
      padding: 8px;
      height: 192px;
      border-radius: 0px;

    fieldset {
      border: none; 
      margin-top: 10px;
    }
    
    &:hover fieldset {
      border: none; 
    }

    &.Mui-focused fieldset {
      border: none; 
    }
  }
  .MuiOutlinedInput-root.Mui-focused {
    box-shadow: none;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset !important;
    -webkit-text-fill-color: black !important;
  }
`;


export const ContactUsCheckboxGroup = styled(Box)`
display: flex;
gap:  16px;
flex-wrap: wrap;
margin-left: 10px;

`
export const ContactUsCheckboxText = styled(Box)`
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    color: #ffff;
    text-align: left;
    line-height: 1.5;
`

export const ContactUsCheckbox = styled(Checkbox)`
  color: white;

  /* Change the border color to white */
  &.MuiCheckbox-root {
    color: white;
  }

  /* Ensure the border color stays white when checked */
  &.Mui-checked {
    color: white;
  }
`;

export const ContactUsButton = styled(Button)`
border: 4px solid #1670ca;
width: fit-content;
height: 47px;
border-radius: 0;
color: #fff;
padding: 20px;
margin-top: 20px;
`
export const ErrorContainer = styled(Box)`
    background: #fff9f9;
    border-radius: 5px;
    border: 1.5px solid #c02b0a;
    box-shadow: 0 1px 4px rgba(0,0,0,.11), 0 0 4px rgba(18,25,97,.0405344);
    margin-block-end: 8px;
    margin-block-start: 8px;
    padding: 8px;
    padding-inline-end: 8px;
    padding-inline-start: 10px;
    width: 98%;
    display: flex;
    align-items: center;
    color: #c02b0a;
    font-size: 13.2px;
    font-weight: 500;
    line-height: 17.6px;
    gap: 10px;
    `
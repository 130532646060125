import React, { useState } from 'react'
import {
  LogoPlaceholder, LogoText, NavbarContainer, NavbarLink, NavbarLinksContainer, HamburgerIcon,
  MobileMenu,
  ExitButton
} from './NavbarStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faB, faBars, faCross, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  };
  return (
    <Box position='relative'>
    <NavbarContainer>
      <LogoPlaceholder>
        <img src='/logo.png' height="44px" alt="Logo"/>
        <LogoText>
          PLANKTOR
        </LogoText>
      </LogoPlaceholder>

      {/* Desktop Links */}
      <NavbarLinksContainer>
        <NavbarLink href='#about'>About</NavbarLink>
        <NavbarLink href='#features'>Why Us</NavbarLink>
        <NavbarLink href='#services'>Services</NavbarLink>
        <NavbarLink href='#contact-us'>Contact Us</NavbarLink>
      </NavbarLinksContainer>

      {/* Hamburger Icon for mobile */}
      <HamburgerIcon onClick={toggleMenu}>
      <FontAwesomeIcon icon={faBars} />
      </HamburgerIcon>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <MobileMenu>
          <ExitButton onClick={toggleMenu}><FontAwesomeIcon icon={faTimes} /></ExitButton>
          <NavbarLink onClick={toggleMenu}>Services</NavbarLink>
          <NavbarLink onClick={toggleMenu}>Our Work</NavbarLink>
          <NavbarLink onClick={toggleMenu}>About</NavbarLink>
          <NavbarLink onClick={toggleMenu}>Contact Us</NavbarLink>
        </MobileMenu>
      )}
    </NavbarContainer>
    </Box>
  )
}

export default Navbar
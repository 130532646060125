import { Box } from '@mui/material'
import React from 'react'
import { ServiceWrapper, ServicesContainer, ServicesHeader, ServicesHeading, ServicesSubHeader, ServicesSubHeading } from './ServicesStyles'

const Services = () => {
  return (
    <Box py='112px' mx='auto' maxWidth='1360px' id="services" >
        <ServicesHeader>Stuff We Do</ServicesHeader>
        <ServicesSubHeader mt='40px' mb='60px'>(Really Well)</ServicesSubHeader>
        <ServicesContainer>
            <Box maxWidth="400px">
                <ServiceWrapper>
                    <img src='/icons/services6.svg' width='49px' height='49px'/>
                    <ServicesHeading>Website Design</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>Tired of websites that are as complicated to manage as rocket science? Meet Planktor, your one-stop solution for creating stunning, fully responsive websites that drive real results.</ServicesSubHeading>
            </Box>
            <Box maxWidth="400px">
                <ServiceWrapper>
                    <img src='/icons/services5.svg' width='49px' height='49px'/>
                    <ServicesHeading>E-commerce Design and Development</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>Planktor crafts tailored, high-performance e-commerce platforms that leverage cutting-edge technology to drive your online business.</ServicesSubHeading>
            </Box>
            <Box>
                <ServiceWrapper>
                    <img src='/icons/services4.svg' width='49px' height='49px'/>
                    <ServicesHeading>Web Hosting</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>Planktor's infrastructure is built on AWS, ensuring lightning-fast performance. Our advanced technology, including a content delivery network (CDN) and caching, guarantees minimal downtime and maximum speed for your online store.</ServicesSubHeading>
            </Box>
            <Box>
                <ServiceWrapper>
                    <img src='/icons/services3.svg' width='49px' height='49px'/>
                    <ServicesHeading>User Experience Design</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>We specialize in simplifying the complex. We transform intricate user interactions into seamless, intuitive experiences that delight your customers.</ServicesSubHeading>
            </Box>
            <Box>
                <ServiceWrapper>
                    <img src='/icons/services2.svg' width='49px' height='49px' />
                    <ServicesHeading>User Interface Design</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>Planktor is renowned for crafting visually stunning, pixel-perfect user interfaces that captivate your audience and leave a lasting impression.</ServicesSubHeading>
            </Box>
            <Box>
                <ServiceWrapper>
                    <img src='/icons/services1.svg' width='49px' height='49px'/>
                    <ServicesHeading>Website Maintenance</ServicesHeading>
                </ServiceWrapper>
                <ServicesSubHeading>Ensures your website stays up-to-date with the latest software and security patches, safeguarding your online presence and optimizing performance.</ServicesSubHeading>
            </Box>
        </ServicesContainer>
    </Box>
  )
}

export default Services
import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { AboutUsContainer, ContentColumn, ContentRow, Heading, Img, ImgWrapper, Section, Subtitle, TextWrapper, TopLine } from './AboutUsStyles'
import { useAnimation } from 'framer-motion'
import { ContactUsContainer } from '../ContactUs/ContactUsStyles'
import { useInView } from 'react-intersection-observer'

const AboutUs = () => {
  const initial = { opacity: 0, y: 30 }
  const animation = useAnimation()

  const { ref, inView } = useInView({ threshold: 0.2 })

  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: 0,
      })
    }
  }, [inView, animation])
  return (
    <Section inverse={false} ref={ref} id='about'>
      <AboutUsContainer>
        <ContentRow>
          <ContentColumn>
            <TextWrapper>
              <TopLine
                initial={initial}
                transition={{ delay: 0.3, duration: 0.6 }}
                animate={animation}
              >
                Your Vision, Our Expertise. Seamless Web Solutions.
              </TopLine>
              <Heading
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
                inverse={false}
              >
                Planktor: Crafting Digital Experiences
              </Heading>
              <Subtitle
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
                inverse={false}
              >
                At Planktor, we're more than just web developers; we're digital architects. We specialize in crafting innovative, scalable, and visually stunning websites and applications that not only meet but exceed your expectations. With a passion for cutting-edge technology and a commitment to user-centric design, we deliver solutions that are both functional and aesthetically pleasing. From complex e-commerce platforms to intuitive mobile apps, our team of skilled professionals is dedicated to turning your ideas into reality. Let's build something extraordinary together.
              </Subtitle>
            </TextWrapper>
          </ContentColumn>
          <ContentColumn
            initial={initial}
            transition={{ delay: 0.5, duration: 0.6 }}
            animate={animation}
          >
            <ImgWrapper>
              <Img
                src={'/bg/john-schnobrich-2FPjlAyMQTA-unsplash.jpg'}
                alt={'aboutus'}
                whileHover={{ rotate: 2, scale: 1.02 }}
                transition={{ duration: 0.5 }}
              />
            </ImgWrapper>
          </ContentColumn>
        </ContentRow>
      </AboutUsContainer>
    </Section>
  )
}

export default AboutUs
import React from 'react'
import { FooterBox, FooterContainer, FooterText } from './FooterStyles'
import { LogoPlaceholder, LogoText } from '../Navbar/NavbarStyles'

const Footer = () => {
  return (
    <FooterBox py='70px'>
      <FooterContainer>
       <LogoPlaceholder>
        <img src='/logo.png' height="44px" alt="Logo"/>
        <LogoText>
          PLANKTOR
        </LogoText>
      </LogoPlaceholder>
      <FooterText mt='30px'>
      © 2024 Planktor Inc.  All Rights Reserved. | <a>Privacy Policy</a>
      </FooterText>
      </FooterContainer>
    </FooterBox>
  )
}

export default Footer
import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const HeroContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-start; 
  height: 100vh; 
  width: 100%;
  background-image: url('/bg/hero-bg.jpg'); 
  background-size: cover; 
  background-position: center; 
  color: white;
  margin-bottom: 50px;
`;

export const HeroContent = styled(Box)`
  padding: 0 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  margin-left: 15px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
    width: 89%;
    padding: 0 10px;
  }
`;


export const HeroHeading = styled(Typography)`
  color: white;
  font-size: 105x;
  font-weight: 700;
  line-height: .9;
  
  @media (max-width: 1200px) {
    font-size: 80px; /* Adjust font-size for large screens */
    max-width: 90%;
  }

  @media (max-width: 768px) {
    font-size: 60px; /* Adjust font-size for medium screens */
    max-width: 100%;
    text-align: center;
  }
`;

export const HeroText = styled(Typography)`
  color: white;
  font-size: 16px;
  font-weight: 700;
`;


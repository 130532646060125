import { Box, Checkbox, CircularProgress, FormControlLabel } from '@mui/material'
import React, { useRef, useState } from 'react'
import { ContactUsButton, ContactUsCheckbox, ContactUsCheckboxGroup, ContactUsCheckboxText, ContactUsContainer, ContactUsDescription, ContactUsError, ContactUsHeader, ContactUsInput, ContactUsInputLarge, ContactUsIntro, ContactUsLabel, ContactUsPhoneInput } from './ContactUsStyles'
import useRecaptcha from '../../Helpers/Recaptcha'
import ReCAPTCHA from 'react-google-recaptcha'
import ErrorBox from '../../Helpers/ErrorBox'
import { contactus } from '../../api/ContactUs'


const ContactUs = () => {
  const { capchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha()
  const [username, setUsername] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [selectedServices, setSelectedServices] = useState([])
  const [inquiry, setInquiry] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const emailRegex= /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const phoneRegex = /^\d{6,}$/
  const contactUsRef = useRef(null)
  const sitekey = "6Ldmry8qAAAAAHTHUFbAFoVfsht9k2B_S1wJ9ZqG"

  const validateFields = () => {
    if(username === '' || username === null || username === undefined){
      return 'Your Name is required'
    }
    if(companyName === '' || companyName === null || companyName === undefined){
      return 'Your Company Name is required'
    }
    if(email === '' || email === null || email === undefined){
      return 'Your Email is required'
    }
    if(!emailRegex.test(email)){
      return 'Your Email is not valid'
    }
    if(phoneNumber === '' || phoneNumber === null || phoneNumber === undefined){
      return 'Your Phone Number is required'
    }
    if(!phoneRegex.test(phoneNumber)){
      return 'Your Phone Number must be digits only'
    }
    if(selectedServices === [] || selectedServices === null || selectedServices === undefined){
      return 'Services is required'
    }
    if(inquiry === '' || inquiry === null || inquiry === undefined){
      return 'Project Details / Inquiry is required'
    }
  }

  const onSubmit = async () => {
    setLoading(true)
    const errorMessage = validateFields()
    if (errorMessage) {
      setError(errorMessage)
      setLoading(false)
      scrollToContactUs()
      return
    }
    if (!capchaToken) {
      setError('Please complete the reCAPTCHA.')
      scrollToContactUs()
      return
    }
    try {
      const res = await contactus({company: companyName, name: username, email, services: selectedServices, message: inquiry, phone: phoneNumber})
      if(res.message){
        setUsername('')
        setCompanyName('')
        setEmail('')
        setSelectedServices([])
        setInquiry('')
        setPhoneNumber('')
        setError(false)
        setLoading(false)
      }
    } catch (error) {
       setError(error.message)
       setLoading(false)
    }
  }

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target
    if (checked) {
      setSelectedServices([...selectedServices, value])
    } else {
      setSelectedServices(selectedServices.filter((selectedServices) => selectedServices !== value))
    }
  }
  

  const scrollToContactUs = () => {
    if (contactUsRef.current) {
      contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <Box bgcolor='#552b56' py='112px' id='contact-us' >
      <ContactUsContainer>
        <Box mx={3} maxWidth='580px'>
          <ContactUsHeader>Contact Us</ContactUsHeader>
          <ContactUsIntro>Let's talk. Planktor offers free consultations to help you understand your design needs and explore the possibilities.</ContactUsIntro>
          <ContactUsDescription>Planktor is your trusted partner for innovative software solutions. With nearly a decade of experience in software development, we specialize in creating custom applications that drive your business forward. Our team of skilled developers combines technical expertise with a deep understanding of user experience to deliver high-quality, efficient software.</ContactUsDescription>
          <ContactUsDescription>We're dedicated to crafting innovative software solutions that exceed expectations. We go beyond the technical aspects to deliver software that is not only functional but also visually appealing and user-friendly. Our clients trust us for our expertise, personalized approach, and commitment to delivering high-quality results.</ContactUsDescription>
          <ContactUsDescription>Need expert advice on your design or development project? Let's connect. We're ready to discuss your goals and provide tailored solutions.</ContactUsDescription>
          <ContactUsDescription>Let’s talk.</ContactUsDescription>
        </Box>
        <Box width='100%' maxWidth='580px' position='relative' ref={contactUsRef}>
         {error && <Box p="10px">
          <ErrorBox errorMessage={error}/>
          </Box>}
          <Box p="10px">
            <ContactUsLabel>Your Name</ContactUsLabel>
            <ContactUsInput value={username} onChange={(e) => setUsername(e.target.value)} />
          </Box>
          <Box p="10px">
            <ContactUsLabel>Company Name</ContactUsLabel>
            <ContactUsInput value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
          </Box>
          <Box p="10px">
            <ContactUsLabel>Email</ContactUsLabel>
            <ContactUsInput value={email} onChange={(e) => setEmail(e.target.value)} />
          </Box>
          <Box p="10px">
            <ContactUsLabel>Phone Number</ContactUsLabel>
            <ContactUsPhoneInput value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </Box>
          <Box p="10px">
            <ContactUsLabel>I'm interested in:(Check all that apply)</ContactUsLabel>
          </Box>
          <ContactUsCheckboxGroup>
          {["Website Design", "Logo Design", "Web Hosting", "Graphic Design", "Other"].map((service) => (
            <FormControlLabel
              key={service}
              control={
                <ContactUsCheckbox
                  value={service}
                  onChange={handleCheckboxChange}
                  checked={selectedServices.includes(service)}
                />
              }
              label={<ContactUsCheckboxText>{service}</ContactUsCheckboxText>}
            />
          ))}
        </ContactUsCheckboxGroup>
          <Box p="10px">
            <ContactUsLabel>Project Details / Inquiry</ContactUsLabel>
            <ContactUsInputLarge multiline rows={10} value={inquiry} onChange={(e) => setInquiry(e.target.value)} />
          </Box>
          <Box pl={1}>
            <ContactUsLabel>reCaptcha Checkbox</ContactUsLabel>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              onChange={handleRecaptcha}
            />
          </Box>
          <Box display='flex' justifyContent='end'>
          <ContactUsButton onClick={onSubmit} disabled={loading}>{loading?<CircularProgress color="inherit" />:'Contact Us'}</ContactUsButton>
          </Box>
        </Box>
      </ContactUsContainer>
    </Box>
  )
}

export default ContactUs
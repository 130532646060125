import React from 'react'
import { HeroContainer, HeroContent, HeroHeading } from './HeroStyles'
import { Box, Typography, useMediaQuery } from '@mui/material'

const Hero = () => {
  const isMobile = useMediaQuery('(max-width: 768px)')
  const isTablet = useMediaQuery('(max-width: 1000px)')

  return (
    <HeroContainer>
      <HeroContent>
        <Box>
        <HeroHeading variant="h1" gutterBottom>
        We Don’t Just Build Applications
        </HeroHeading>
        <Typography variant="body1">
        We Create Digital Experiences. Dive into Seamless Design and Easy Management with Planktor!
        </Typography>
        </Box>
        <Box justifySelf='end' width='100%'>
          <img src='/bg/hero-pic3.png' width="100%" height={isMobile? '250px': isTablet? '300px': '450px'} />
        </Box>
      </HeroContent>
    </HeroContainer>
  )
}

export default Hero
import styled from "@emotion/styled";
import { Box } from "@mui/material";


export const FeaturesContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
  gap: 16px; /* Adjusts spacing between grid items */
  justify-content: center;
  padding: 0 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); /* 3 equal columns */
  }
`;

export const FeatureWrapper = styled(Box)`
  display: flex;
  gap: 16px; 
  margin: 80px 0;
  @media (max-width: 768px) {
    font-size: 19px;
    font-weight: 400;
    gap: 8px;
  }
`;

export const FeaturesHeader = styled(Box)`
    font-size: 42px;
    font-weight: 700;
    color: #161616;
    line-height: 1.125;
    text-align: center;
`;

export const FeaturesSubHeader = styled(Box)`
    font-size: 16px;
    font-weight: 300;
    color: #333333;
    text-align: center;
    line-height: 1.5;

`;

export const FeaturesHeading = styled(Box)`
    font-size: 28px;
    font-weight: 400;
    color: #161616;
    line-height: 1.25;
    margin-bottom: 5px;
    @media (max-width: 768px) {
        font-size: 19px;
        font-weight: 400;
      }
`;

export const FeaturesOrder = styled(Box)`
    font-size: 35px;
    font-weight: 700;
    background: #f9c80b;
    border-radius: 100%;
    width: 90px;
    height: 90px;
    min-width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FeaturesSubHeading = styled(Box)`
    font-size: 16px;
    font-weight: 300;
    color: #808285;
`;

export const FeaturesLine = styled(Box)`
    max-width: 73px;
    margin: auto;
    border-top-width: 3px;
    border-top-style: solid;
    border-top-color: #1670ca;
`

export const FeatureCardContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 16px; 
  justify-content: center;
  padding: 0 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr); 
  }
`
export const FeatureCard = styled(Box)`
  height: 100%;
  background-image: url('/bg/hero-bg.jpg');
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  min-width: 200px;
  color: white;
  font-weight: 500;
  font-size: 24px;
  padding: 0px 20px;
  gap: 24px;
  perspective: 1000px; /* Adding perspective to the container */
  
  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
`;

export const FlipCardInner = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
`;

export const FlipCardFront = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  text-align: center;
`;

export const FlipCardBack = styled(FlipCardFront)`
  transform: rotateY(180deg);
  text-align: center;
`;
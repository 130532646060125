import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

// Navbar container for overall layout
export const NavbarContainer = styled(Box)`
  display: grid;
  position: absolute;
  top: 0;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  justify-content: center;
  z-index: 999;
  background: none;
  height: 44px;
  padding: 19px 0px 22px; 
  margin-left: 40px; 
  width: 95%;
  @media (max-width: 768px) {
    width: 89%;
    grid-template-columns: 1fr 1fr;
  }
`;

// Logo container
export const LogoPlaceholder = styled(Box)`
  display: flex;
  height: 100%;
  gap: 12px;
`;

// Logo text
export const LogoText = styled(Typography)`
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  color: #ffffff;
`;

// Navbar links container for desktop
export const NavbarLinksContainer = styled(Box)`
  display: flex;
  height: 100%;
  gap: 30px;
  justify-self: end;
  margin-right: 30px;
  @media (max-width: 1105px) {
    display: none; /* Hide links on small screens */
  }
`;

// Individual navbar link
export const NavbarLink = styled.a`
  font-size: 17px;
  line-height: 44px;
  color: #ffff;
  cursor: pointer;
  text-decoration: none;
  transition: color 0.1s ease-in; 
  &:hover {
    color: #1670ca;
  }
`;

// Hamburger icon for mobile
export const HamburgerIcon = styled(Box)`
  display: none;
  font-size: 28px;
  cursor: pointer;

  @media (max-width: 1105px) {
    display: block;
    justify-self: end;
    color: #ffff;
    margin-right: 20px;
  }
`;

// Mobile menu that appears when hamburger is clicked
export const MobileMenu = styled(Box)`
display: flex;
margin-right: 100px;
flex-direction: column;
gap: 20px;
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.9);
padding: 50px 0px;
z-index: 5;
text-align: center;

@media (min-width: 1105px) {
  display: none;
}

a {
  color: #ffffff;
  font-size: 24px;
  text-align: center;
}
`;

export const ExitButton = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 36px;
  color: #ffffff;
  cursor: pointer;
`;

export const ScrollTopButton = styled(Box)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: transperant;
  color: white;
  border: none;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  padding: 0 !important;
  margin: 0 !important;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
  `